// --- Vendors ---
import { Foundation } from 'foundation-sites/js/foundation.core';
import { Reveal } from 'foundation-sites/js/foundation.reveal';
Foundation.plugin(Reveal, 'Reveal');
import GLightbox from 'glightbox';

import Swiper, { Navigation, Pagination, Thumbs, Mousewheel } from 'swiper';
Swiper.use([Navigation, Pagination, Thumbs, Mousewheel]);

// --- Components ---
import { utils } from '../components/_utils';


const homePage = {
    readyFn: function () {
        console.log("start video intro");
        let $video = $('#introduction video');
        let srcVideoPoster = $video.data('desktop-poster');
        let srcVideo = $('#introduction video source').data('desktop-src');

        if (utils.getDeviceKind() !== 'isDesktop') {
            srcVideoPoster = $video.data('mobile-poster');
            srcVideo = $('#introduction video source').data('mobile-src');
        }

        $video.attr('poster', srcVideoPoster);
        $('#introduction video source').attr('src', srcVideo);
        $video[0].load();
        $video[0].play();

        //Swiper produit
        window.wineSwiper = new Swiper('.swiper-wine-container', {
            slidesPerView: 1,
            spaceBetween: 0,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                980: {
                    slidesPerView: 4,
                },
            }
        });


        //Swiper selection
        var wineSelectionSwiper = new Swiper('.swiper-selection-wine-container', {
            slidesPerView: 1,
            spaceBetween: 0,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                980: {
                    slidesPerView: 3.2,
                },
            }
        });

        //Swiper Galerie
        console.log("swiper Galerie ready")
        var swiperGalerie = new Swiper('.swiper-galerie', {
            slidesPerView: 4,
            spaceBetween: 0,
            breakpoints: {
                980: {
                    slidesPerView: 4,
                },
                1200: {
                    slidesPerView: 5
                },
            }
        });


        /* FILTRE VIN */

        $("#products .tag").on("click", function () {
            if ($(this).hasClass("actif")) {
                $(this).removeClass("actif");
                $(".swiper-wine-container .swiper-wine").show();
                $(".swiper-wine-container .swiper-wine").addClass("swiper-slide");
            } else {
                $("#products .tag").removeClass("actif");
                $(this).addClass("actif");
                $(".swiper-wine-container .swiper-wine").show();
                $(".swiper-wine-container .swiper-wine").addClass("swiper-slide");
                var filtre = $(this).attr("data-filter");
                $(".swiper-wine-container .swiper-wine:not(" + filtre + ")").hide();
                $(".swiper-wine-container .swiper-wine:not(" + filtre + ")").removeClass("swiper-slide");

                console.log(wineSwiper);

                window.wineSwiper.slideTo(0);
                window.wineSwiper.updateSlidesClasses();
                window.wineSwiper.update();

            }
        });

        /* FILTRE VIN SELECT MOBILE */
        $("#filtre-origine").on("change", function () {
            $(".swiper-wine-container .swiper-wine").show();
            $(".swiper-wine-container .swiper-wine").addClass("swiper-slide");
            var filtre = $(this).val();
            $(".swiper-wine-container .swiper-wine:not(" + filtre + ")").hide();
            $(".swiper-wine-container .swiper-wine:not(" + filtre + ")").removeClass("swiper-slide");


            window.wineSwiper.slideTo(0);
            window.wineSwiper.updateSlidesClasses();
            window.wineSwiper.update();

        });

    },
}
export { homePage };