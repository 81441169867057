// --- Vendors ---
// --- Components ---
const header = {
    contactUrl: '',

    readyFn: function () {
        let self = this;

        $('.hide-des').on('click', function () {
            $("#menu-mobile").slideToggle();
            $("body").delay('200').toggleClass("menu_actif");
        });


        var position = $("header").scrollTop();
        var windowScrollTop = 0;
        $(window).on('scroll', function () {
            windowScrollTop = $(window).scrollTop();

            if (windowScrollTop > position) {
                document.getElementsByTagName('header')[0].classList.add('scrolled');
            } else {
                document.getElementsByTagName('header')[0].classList.remove('scrolled');
            }
        });


        $(".burger-menu").on("change", function () {
            $("#container-menu-mobile").toggleClass("actif");
            $("#side-menu").toggleClass("actif");
            $("#sticky-header").toggleClass("hide");
            if ($(this).hasClass("actif")) {
                $("input.burger-menu").prop("checked", false);
            } else {
                $("input.burger-menu").prop("checked", true);
            }

            $("input.burger-menu").toggleClass("actif");
        });
    },
}
export { header };